import React from 'react';
import {
  ChakraProvider,
  Box,
  Grid,
  theme,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';

import { AuthProvider } from './firebase/auth';
import { DataProvider } from './firebase/data';
import MyCalendar from './Components/Calendar/Calendar';
import Todo from './Components/Todo/Todo';
import Nav from './Components/Nav';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Box textAlign="center" fontSize="xl">
          <Nav />
          <Flex wrap={'wrap'} justifyContent="center">
            <DataProvider>
              <Box>
                <MyCalendar />
              </Box>

              <Box>
                <Todo />
              </Box>
            </DataProvider>
          </Flex>
        </Box>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;

// {id: '_0HH4n8JwU0lltHGLy2lW', body: 'two', check: false}
