import { Heading, VStack, useToast } from '@chakra-ui/react';
import Task from './Task';
import AddTask from './AddTask';
import { useState, useEffect } from 'react';
import { useData } from '../../firebase/data';
export default function TaskList() {
  const toast = useToast();
  const { data, showingDate, updateData } = useData();

  const tasks = data[showingDate] || [];
  console.log(data[showingDate], tasks);

  function deleteTask(id) {
    // console.log('Deleting task with id: ' + id);
    const newTasks = tasks.filter(task => {
      return task.id !== id;
    });
    setTasks(newTasks);
  }

  function checkTask(id) {
    const newTasksCheck = tasks.map((task, index, array) => {
      if (task.id === id) {
        task.check = !task.check;
      }
      return task;
    });

    setTasks(newTasksCheck);
  }

  function updateTask(id, body, onClose) {
    const info = body.trim();

    if (!info) {
      toast({
        title: "Task can't be empty",
        position: 'top',
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });

      return;
    }

    const newTasksUpdate = tasks.map((task, index, array) => {
      if (task.id === id) {
        task.body = body;
        task.check = false;
      }
      return task;
    });

    setTasks(newTasksUpdate);

    onClose();
  }
  function setTasks(tasks) {
    data[showingDate] = tasks;
    updateData(data);
  }
  function addTask(task) {
    data[showingDate] = data[showingDate] || [];
    data[showingDate].push(task);
    updateData(data);
    // setTasks([...tasks, task]);
  }

  return (
    <VStack p={4} minH="100vh" minW="60vw" maxW="60vw">
      <Heading p="5" fontWeight="extrabold" size="xl">
        Task List For: {showingDate}
      </Heading>
      <AddTask addTask={addTask} />
      <Task
        tasks={tasks}
        updateTask={updateTask}
        deleteTask={deleteTask}
        checkTask={checkTask}
      />
    </VStack>
  );
}
