import {
  getFirestore,
  collection,
  getDoc,
  setDoc,
  doc,
} from 'firebase/firestore';
import firebase from './firebase';

const db = getFirestore(firebase);
const userRef = collection(db, 'users');

export const getUserData = async uid => {
  try {
    console.log('getting data');
    const d = doc(db, 'users', uid);
    let user = await getDoc(d);
    user = user.data();
    return user;
  } catch (err) {
    console.log(err);
    return {};
  }
};

const getUserDocRef = uid => {
  return doc(db, 'users', uid);
};

export const setUserData = async (uid, data) => {
  try {
    console.log('setting data');
    const docRef = getUserDocRef(uid);
    return await setDoc(docRef, data);
  } catch (err) {
    console.log(err);
    return false;
  }
};
