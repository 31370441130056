import React from 'react';
import UpdateTask from './UpdateTask';
import { DeleteTask } from './DeleteTask';
import {
  HStack,
  Box,
  VStack,
  Text,
  StackDivider,
  Heading,
} from '@chakra-ui/react';

function Tasks({ tasks, updateTask, deleteTask, checkTask }) {
  if (!tasks.length) {
    return (
      <>
        <Box maxW="100%">
          <Heading size="lg">Your schedule is clear!</Heading>
        </Box>
      </>
    );
  }

  return (
    <>
      <VStack
        divider={<StackDivider />}
        borderColor="teal.500"
        borderWidth="2px"
        p="5"
        borderRadius="lg"
        minW="450"
        maxW="90%"
        alignItems="stretch"
      >
        {tasks.map(task => (
          <HStack key={task.id} opacity={task.check === true ? '0.2' : '1'}>
            <Text
              w="100%"
              p="8px"
              borderRadius="lg"
              as={task.check === true ? 's' : ''}
              cursor="pointer"
              onClick={() => checkTask(task.id)}
            >
              {task.body}
            </Text>
            <DeleteTask task={task} deleteTask={deleteTask} />
            <UpdateTask task={task} updateTask={updateTask} />
          </HStack>
        ))}
      </VStack>
    </>
  );
}

export default Tasks;
