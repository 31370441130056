import { createContext, useState, useEffect, useContext } from 'react';
import { setUserData, getUserData } from './db';
import { useAuth } from './auth';

const dataContext = createContext();
export const useData = () => useContext(dataContext);

export const DataProvider = ({ children }) => {
  const data = useDataFromDB();
  return <dataContext.Provider value={data}>{children}</dataContext.Provider>;
};

const useDataFromDB = () => {
  const d = new Date();
  const [data, setData] = useState({});
  console.log('use effect');
  const [showingDate, setShowingDate] = useState(
    `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
  );
  const auth = useAuth();
  useEffect(() => {
    const func = async () => {
      if (!auth.user) return setData({});
      const userData = await getUserData(auth.user.uid);
      //   if (!userData.data) {
      //     console.log(userData,'Resetting Data');
      //     setUserData(auth.user.uid, data);
      //   }
      setData(userData || {});
    };
    func();
  }, [auth]);

  const updateData = async data => {
    setUserData(auth.user.uid, data);
    const newData = { ...data };
    setData(newData);
  };

  return { data, updateData, showingDate, setShowingDate };
};
