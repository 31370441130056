import React from 'react';
import { Box, Text, Flex, Button, Heading } from '@chakra-ui/react';
import ColorModeSwitcher from './ColorModeSwitcher';
import { useAuth } from '../firebase/auth';
export default function Nav() {
  return (
    <Flex
      minW="100vw"
      maxW="100vw"
      height="10vh"
      alignItems="center"
      boxShadow="base"
    >
      <Box ml="10">
        <Heading
          fontWeight="extrabold"
          size="xl"
          bgGradient="linear(to-l, teal.400, blue.400)"
          bgClip="text"
        >
          Minimalist Calendar
        </Heading>
      </Box>
      <Flex ml="auto" mr="10">
        <Signin />
        <ColorModeSwitcher fontSize="2rem" />
      </Flex>
    </Flex>
  );
}

const Signin = () => {
  const { user, signin, signout } = useAuth();
  const handleAuth = () => {
    user ? signout() : signin();
  };
  return (
    <Box mr="10">
      <Button
        color="white"
        bg="teal.500"
        onClick={handleAuth}
        colorScheme={'teal'}
        size="lg"
      >
        {user ? `Sign Out` : `Sign In`}
      </Button>
    </Box>
  );
};
