import React from 'react';
import Calendar from 'react-calendar';
import { Box } from '@chakra-ui/react';
import './Calendar.css';
import { useData } from '../../firebase/data';

export default function MyCalendar() {
  const { setShowingDate } = useData();
  return (
    <Box maxW="500" maxH="360" minW="500" minH="360" m="10">
      <Calendar
        onClickDay={d =>
          setShowingDate(
            `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
          )
        }
      />
    </Box>
  );
}
