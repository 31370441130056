import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAqa520SIWQexOmTKMgbBDwYemuIgW6rkI',
  authDomain: 'calendar-91be0.firebaseapp.com',
  projectId: 'calendar-91be0',
  storageBucket: 'calendar-91be0.appspot.com',
  messagingSenderId: '1047967046644',
  appId: '1:1047967046644:web:5365994e5e48728d0e77a1',
};

const app = initializeApp(firebaseConfig);
export default app;
