import React from 'react';
import TaskList from './TaskList';
import { useAuth } from '../../firebase/auth';
import {
  Box,
  Grid,
  theme,
  Button,
  Text,
  Flex,
  Heading,
} from '@chakra-ui/react';

export default function Todo() {
  const auth = useAuth();

  return <Box>{auth.user ? <TaskList /> : <UserNotLoggedIn />}</Box>;
}

const UserNotLoggedIn = () => {
  return (
    <Box minW="100vw" m="10">
      <Heading p="5" fontWeight="extrabold" size="xl">
        Please log in using Google to use the app
      </Heading>
    </Box>
  );
};
